.nav-link:hover {
    background-color: #576574;
    transition: 0.4s;
}
.logo-title {
    color: yellow;
    transition: 0.4s;
}
.logo-title:hover {
    color: #fff;
    background: transparent;
    text-shadow: 2px 2px blueviolet;
}